import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { TextField, Container } from "@material-ui/core";
import { Typography, Button } from "@ohif/ui";
import validator from "validator";
import { VpnKeyRounded } from "@material-ui/icons";

import useStyles from "styles/userProfileStyles";
import PasswordModal from "components/UserProfile/PasswordModal/PasswordModal";
import PasswordChangeContent from "components/UserProfile/PasswordChangeContent/PasswordChangeContent";
import { updateUser, getUsers } from "api/user";
import { getUser } from "state/reducers/auth";
import { useAuth } from "state/selector";

const UserProfileBox = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showAdditionalField, setShowAdditionalField] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const userEmail = user?.email;
  const userName = user?.name;
  const userPhoneNumber = user?.phone_number;

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const confirmEmailRef = useRef();

  const handlePasswordModalClose = () => {
    setOpenPasswordModal(false);
  };

  const handleClick = () => {
    onClose();
  };

  const handleTextFieldClick = () => {
    setShowAdditionalField(true);
  };

  const onChangeData = async e => {
    if (isValid) {
      const formData = new FormData();
      formData.append("name", nameRef.current.value);
      formData.append("email", emailRef.current.value);
      formData.append("phone_number", phoneNumberRef.current.value);
      await updateUser(user.id, formData);
      dispatch(getUser());
    } else {
      alert("Invalid Email or Phone Number");
    }
  };

  const validateEmail = e => {
    const email = e.target.value;
    if (showAdditionalField) {
      if (emailRef.current.value === confirmEmailRef.current.value && validator.isEmail(email)) {
        setEmailError("");
        setIsValid(true);
      } else {
        if (!validator.isEmail(email)) {
          setEmailError("Invalid Email");
        } else {
          setEmailError("Mismatching emails");
        }
        setIsValid(false);
      }
    } else {
      setEmailError("");
      setIsValid(true);
    }
  };

  const validatePhone = e => {
    const phone = e.target.value;
    if (validator.isMobilePhone(phone)) {
      setPhoneError("");
      setIsValid(true);
    } else {
      setPhoneError("Invalid Phone Number");
      setIsValid(false);
    }
  };

  // https://github.com/mui/material-ui/issues/19096

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const Section = ({ title, children }) => (
    <>
      <div className="border-b-2 border-black mb-2">
        <Typography variant="h5" className="flex grow text-primary-light font-light pb-2">
          {title}
        </Typography>
      </div>
      <div className="mt-4">{children}</div>
    </>
  );

  return (
    <div className="p-2">
      <Container
        title="User Preferences"
        style={{ display: "flex", flexDirection: "column" }}
        className={classes.reportBtn}
      >
        <Section title="User Profile Settings" />
        <div>
          <TextField
            name="Name"
            defaultValue={userName}
            variant="outlined"
            inputRef={nameRef}
            label="Name"
            className={classes.textField}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={classes.textFieldContainer}>
          <TextField
            name="Email"
            defaultValue={userEmail}
            variant="outlined"
            label="Email"
            inputRef={emailRef}
            className={classes.textField}
            onKeyDown={onKeyDown}
            onChange={e => validateEmail(e)}
            onClick={handleTextFieldClick}
          />
          {showAdditionalField && (
            <TextField
              name="Additional Field"
              variant="outlined"
              label="Confirm Email Change"
              defaultValue=""
              className={classes.textField}
              onKeyDown={onKeyDown}
              onChange={e => validateEmail(e)}
              inputRef={confirmEmailRef}
            />
          )}
          <div style={{ color: "red", whiteSpace: "nowrap" }} className={classes.spanEmailField}>
            {emailError}
          </div>
        </div>
        <div className={classes.textFieldContainer}>
          <TextField
            name="Phone Number"
            defaultValue={userPhoneNumber}
            variant="outlined"
            label="Phone Number"
            inputRef={phoneNumberRef}
            className={classes.phoneNumberTextField}
            onChange={e => validatePhone(e)}
          />
          <div style={{ color: "red", whiteSpace: "nowrap" }} className={classes.spanPhoneField}>
            {phoneError}
          </div>
        </div>

        <Button
          startIcon={<VpnKeyRounded />}
          variant="outlined"
          onClick={() => setOpenPasswordModal(true)}
          className={classes.passwordButton}
        >
          Change Password
        </Button>
        <PasswordModal
          openPopup={openPasswordModal}
          setOpenPopup={setOpenPasswordModal}
          onClose={handlePasswordModalClose}
        >
          <PasswordChangeContent handleClose={handlePasswordModalClose} />
        </PasswordModal>

        <div className="flex flex-row justify-end">
          <Button variant="outlined" border="light" onClick={handleClick}>
            Cancel
          </Button>
          <Button variant="contained" color="light" border="light" className="ml-2" onClick={onChangeData}>
            Save
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default UserProfileBox;
