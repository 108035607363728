import React, { createContext, useContext, useState, useEffect, useRef, useCallback, useMemo } from "react";
import { usePubNub } from "pubnub-react";
import debounce from "lodash.debounce";
import { ChannelEntity, Themes, usePresence, useUserMemberships } from "@pubnub/react-chat-components";
import { useLocation } from "react-router-dom";
import { getUsers } from "api/user";
import { useAuth } from "state/selector";
import { DEFAULT_CHANNEL, DIRECT_CHANNEL_PREFIX, GENERAL_CHANNEL } from "utils/constants";
import { User } from "types/models/User";
import Pubnub, { MessageEvent } from "pubnub";
import { ProviderProps } from "types/ProviderProps";
import { UserFieldOption } from "types/UserFieldOption";

type ChatContextType = {
  pubnub: Pubnub;
  uuid: string;
  currentChannel: ChannelEntity;
  setCurrentChannel: (channel: ChannelEntity) => void;
  directChannels: ChannelEntity[];
  groupChannels: ChannelEntity[];
  studyChannels: ChannelEntity[];
  userList: User[];
  presentUUIDs: string[];
  registerTimeToken: (channelIds: string[], timeToken?: number | string) => void;
  unreadMessageCounts: UnreadMessageCounts;
  refetchJoinedChannels: () => void;
  theme: Themes;
  userFieldOptions: UserFieldOption[];
};

type UnreadMessageCounts = {
  [channel: string]: number;
};

function useStateRef<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

const sortChannelFunc = (c1: ChannelEntity, c2: ChannelEntity) => c1.id.localeCompare(c2.id);

const getStudyChannelId = (studyId: string) => `study.${studyId}`;

const ChatContext = createContext<ChatContextType>({} as any);

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: ProviderProps) => {
  const chatHook = useChatHook();
  return <ChatContext.Provider value={chatHook}>{children}</ChatContext.Provider>;
};

function useChatHook() {
  const { user } = useAuth();
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const { pathname } = useLocation();
  const [theme] = useState<Themes>("dark");
  const [currentStudy, setCurrentStudy] = useState("");
  const [currentChannel, setCurrentChannel] = useState<ChannelEntity>(DEFAULT_CHANNEL);
  const currentChannelRef = useStateRef(currentChannel);
  const [userList, setUserList] = useState<User[]>([]);
  const [unreadMessageCounts, setUnreadMessageCounts] = useState<UnreadMessageCounts>({});
  const [joinedChannels, , refetchJoinedChannels] = useUserMemberships({
    include: { channelFields: true, customChannelFields: true, customFields: true },
  });
  const [pubnubListenerInitialized, setPubnubListenerInitialized] = useState(false);

  const [channels, setChannels] = useState<ChannelEntity[]>([]);

  const groupChannels = useMemo(
    () =>
      channels
        .filter(c => c.id.startsWith(GENERAL_CHANNEL.id))
        .map(c => {
          if (c.id === GENERAL_CHANNEL.id) {
            c.name = GENERAL_CHANNEL.name;
          }

          return c;
        })
        .sort(sortChannelFunc),
    [channels]
  );
  const groupChannelsRef = useStateRef(groupChannels);

  const directChannels = useMemo(
    () =>
      channels
        .filter(c => c.id.startsWith(`${DIRECT_CHANNEL_PREFIX}.`))
        .map(c => {
          const interlocutorId = c.id.replace(uuid, "").replace(`${DIRECT_CHANNEL_PREFIX}.`, "").replace("@", "");
          const interlocutor = userList.find(u => u.id === interlocutorId);

          if (interlocutor) {
            c.name = interlocutor.name;
            c.custom = {
              interlocutorName: interlocutor.name,
              interlocutorPicture: interlocutor.profile_picture || "",
              interlocutorId,
            };
          }

          return c;
        })
        .sort(sortChannelFunc),
    [channels, userList, uuid]
  );
  const directChannelsRef = useStateRef(directChannels);

  const studyChannels = useMemo(
    () => channels.filter(c => c.id === getStudyChannelId(currentStudy)).sort(sortChannelFunc),
    [channels, currentStudy]
  );

  const [presenceData] = usePresence({
    channels: channels.length ? channels.map(c => c.id) : [currentChannel.id],
  });

  const presentUUIDs = presenceData[currentChannel.id]?.occupants?.map(o => o.uuid);

  const studyUUIDs =
    (currentStudy && presenceData?.[getStudyChannelId(currentStudy)]?.occupants?.map(o => o.uuid)) || [];

  const refreshUnreadCounts = debounce(async () => {
    if (!groupChannelsRef.current || !directChannelsRef.current) {
      return;
    }

    const channelIds: string[] = [...groupChannelsRef.current, ...directChannelsRef.current].map(ch => ch.id);

    if (channelIds.length === 0) {
      return;
    }

    const time = await pubnub.time();
    let { data } = await pubnub.objects.getMemberships({
      include: {
        customFields: true,
      },
    });

    data = data.filter(item => channelIds.includes(item.channel.id));
    const lastReadTimeToken: { [index: string]: number } = {};

    data.forEach(item => {
      lastReadTimeToken[item.channel.id] =
        (item.custom?.lastReadTimeToken as number) || time.timetoken - 3600 * 24 * 15 * 10000000;
    });

    const channelTimetokens: number[] = channelIds.map(channelId => {
      if (lastReadTimeToken[channelId]) {
        return lastReadTimeToken[channelId];
      }
      return time.timetoken;
    });

    pubnub.messageCounts(
      {
        channels: channelIds,
        channelTimetokens,
      },
      (status, results) => setUnreadMessageCounts(results.channels)
    );
  }, 350);

  const registerTimeToken = useCallback(
    async (channelIds: string[], timeToken?: number | string) => {
      if (!timeToken) {
        timeToken = (await pubnub.time()).timetoken;
      }

      pubnub.objects
        .setMemberships({
          channels: channelIds.map(channelId => ({
            id: channelId,
            custom: {
              lastReadTimeToken: String(timeToken),
            },
          })),
        })
        .then(() => refreshUnreadCounts());
    },
    [pubnub, refreshUnreadCounts]
  );

  const pubnubListener = useMemo(
    () => ({
      message: (message: MessageEvent) => {
        const curChannel = currentChannelRef.current;

        if (!curChannel) {
          return;
        }

        if (message.publisher !== uuid && curChannel.id !== message.channel) {
          refreshUnreadCounts();
        } else {
          registerTimeToken([message.channel], message.timetoken);
        }
      },
    }),
    [currentChannelRef, uuid, refreshUnreadCounts, registerTimeToken]
  );

  useEffect(() => {
    if (joinedChannels.length === 0) {
      return;
    }

    if (joinedChannels.length !== channels.length) {
      setChannels([...joinedChannels]);
    }
  }, [joinedChannels, channels]);

  useEffect(() => {
    if (!pubnub || !user) {
      return;
    }

    async function initGeneralChannel() {
      await pubnub.objects.setChannelMembers({ channel: GENERAL_CHANNEL.id, uuids: [user!.id] });
      refetchJoinedChannels();
    }

    async function initStudyView(studyId: string) {
      await pubnub.objects.setChannelMembers({ channel: getStudyChannelId(studyId), uuids: [user!.id] });
      refetchJoinedChannels();
      setCurrentStudy(studyId);
    }

    if (groupChannels.length === 0 && !pubnubListenerInitialized) {
      initGeneralChannel();
    }

    // when pubnub view finishes its initialization
    if (groupChannels.length > 0 && !pubnubListenerInitialized) {
      setPubnubListenerInitialized(true);
      pubnub.addListener(pubnubListener);

      if (pathname.startsWith("/study/")) {
        const studyId = pathname.replace("/study/", "");
        initStudyView(studyId);
        refreshUnreadCounts();
      } else if (pathname.startsWith("/chat")) {
        setCurrentChannel(GENERAL_CHANNEL);
        registerTimeToken([GENERAL_CHANNEL.id]);
      } else {
        refreshUnreadCounts();
      }
    }
  }, [
    pubnub,
    user,
    pathname,
    groupChannels,
    pubnubListenerInitialized,
    pubnubListener,
    refetchJoinedChannels,
    refreshUnreadCounts,
    registerTimeToken,
  ]);

  useEffect(() => {
    const getUserList = async () => {
      const { results: users } = await getUsers();
      setUserList(users);
    };

    getUserList();

    return () => {
      setUserList([]);
    };
  }, []);

  const userFieldOptions = useMemo(
    () =>
      userList
        .map(u => ({
          label: u.name || u.email,
          value: u.id,
          user: u,
        }))
        .sort((u1, u2) => u1.label.localeCompare(u2.label)),
    [userList]
  );

  return {
    pubnub,
    uuid,
    userList,
    userFieldOptions,
    unreadMessageCounts,
    refreshUnreadCounts,
    currentChannel,
    setCurrentChannel,
    directChannels,
    groupChannels,
    studyChannels,
    presenceData,
    presentUUIDs,
    studyUUIDs,
    theme,
    registerTimeToken,
    refetchJoinedChannels,
  };
}
