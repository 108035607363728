import React, { FC, Suspense } from "react";
import { Box } from "@material-ui/core";

import { Chat, UserEntity } from "@pubnub/react-chat-components";
import { Routes, Route, Navigate } from "react-router-dom";
import { useChat } from "hooks/useChat";
import NavBar from "components/NavBar/NavBar";
import { ROUTE_PATH_LIST, ROUTE_COMPONENT_LIST } from "routes/routes";

const MainWrapper: FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const { currentChannel, userList, groupChannels, directChannels, studyChannels, theme } = useChat();

  return (
    <Box flexDirection="column" display="flex" height="100vh">
      {currentChannel && (
        <Chat
          theme={theme}
          users={userList as unknown as UserEntity[]}
          currentChannel={currentChannel.id}
          channels={[
            ...groupChannels.map(c => c.id),
            ...directChannels.map(c => c.id),
            ...studyChannels.map(c => c.id),
          ]}
        >
          <NavBar />
          {children}
        </Chat>
      )}
    </Box>
  );
};

const AppRoutes: FC = () => (
  <Suspense fallback={<div />}>
    <Routes>
      {ROUTE_PATH_LIST.map(({ path, name }) => (
        <Route
          key={name}
          path={path}
          element={<MainWrapper>{React.createElement(ROUTE_COMPONENT_LIST[name])}</MainWrapper>}
        />
      ))}
      <Route path="/" element={<Navigate to="/studies" />} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
