import { AnyObject } from "types/AnyObject";

export function getExtensionId(): string {
  const extensionIdEl = document.getElementById("nl-extension-id") as HTMLInputElement;
  return extensionIdEl ? extensionIdEl.value : "";
}

export function sendExtensionMessage(params: AnyObject) {
  const extensionId = getExtensionId();

  if (extensionId) {
    chrome.runtime.sendMessage(extensionId, params);
    return true;
  }

  return false;
}
