import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Swal from "sweetalert2/dist/sweetalert2";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import MonitorIcon from "@material-ui/icons/Tv";
import { EmojiObjects, Call, Send, Textsms } from "@material-ui/icons";
import { setup, isSupported } from "@loomhq/record-sdk";
import useStyles from "styles/appStyles";
import { theme } from "styles/theme";
import { getLoomToken } from "api/token";
import { createRecording } from "api/recording";

const START_RECORD_BUTTON_ID = "loom-record-sdk-button";

const StudyMenu = ({ study, setRecordingDialogOpen }) => {
  const classes = useStyles();
  const [isRecordingEnabled, setIsRecordingEnabled] = useState(false);
  const [recordAnchorEl, setRecordAnchorEl] = useState(null);
  const [referringPhysicianAnchorEl, setReferringPhysicianAnchorEl] = useState(null);

  useEffect(() => {
    async function setupLoom() {
      const { supported, error } = await isSupported();

      if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        return;
      }

      const tokenData = await getLoomToken();
      const button = document.getElementById(START_RECORD_BUTTON_ID);

      const { configureButton } = await setup({
        jws: tokenData.token,
        config: {
          insertButtonText: "Save",
          enableOnboardingTutorial: false,
        },
      });

      const recordingButton = configureButton({ element: button });

      recordingButton.on("insert-click", async video => {
        const { value } = await Swal.fire({
          input: "text",
          title: "Enter Recording Title",
          inputPlaceholder: "Recording Title",
        });

        if (value) {
          await createRecording({
            url: video.sharedUrl,
            title: value,
            study: study.id,
          });
        }
      });

      setIsRecordingEnabled(true);
    }

    if (study.id) {
      setupLoom();
    }
  }, [study.id]);

  const handleReferringPhysicianClick = event => {
    setReferringPhysicianAnchorEl(event.currentTarget);
  };

  const handleReferringPhysicianClose = () => {
    setReferringPhysicianAnchorEl(null);
  };

  const handleRecordMenuClick = event => {
    setRecordAnchorEl(event.currentTarget);
  };

  const handleRecordMenuClose = () => {
    setRecordAnchorEl(null);
  };

  const handleScreenRecordingClick = () => {
    setRecordAnchorEl(null);
    const button = document.getElementById(START_RECORD_BUTTON_ID);
    button.click();
  };

  const handleAllScreenRecordingClick = () => {
    setRecordingDialogOpen(true);
    setRecordAnchorEl(null);
  };

  return (
    <>
      <Box style={{ display: "none" }} id={START_RECORD_BUTTON_ID} />
      {isRecordingEnabled && (
        <>
          <IconButton onClick={handleRecordMenuClick} style={{ marginLeft: "0.5em" }}>
            <RadioButtonCheckedIcon />
          </IconButton>
          <Menu
            anchorEl={recordAnchorEl}
            open={Boolean(recordAnchorEl)}
            onClose={handleRecordMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            className={classes.navBarMenu}
            sx={{ background: theme.palette.other.dark }}
          >
            <MenuItem onClick={handleScreenRecordingClick}>
              <RadioButtonCheckedIcon fontSize="small" />
              <Box ml={1}>Start Recording</Box>
            </MenuItem>
            <MenuItem onClick={handleAllScreenRecordingClick}>
              <MonitorIcon fontSize="small" />
              <Box ml={1}>All Screen Recordings</Box>
            </MenuItem>
          </Menu>
        </>
      )}
      <Tooltip title="AI Lens is in development">
        <IconButton>
          <EmojiObjects />
        </IconButton>
      </Tooltip>
      <>
        <Tooltip title={`Page (Referring: ${study.referring_physician})`}>
          <IconButton
            aria-controls="referring-physician-menu"
            aria-haspopup="true"
            onClick={handleReferringPhysicianClick}
          >
            <Call />
          </IconButton>
        </Tooltip>
        <Menu
          id="referring-physician-menu"
          anchorEl={referringPhysicianAnchorEl}
          keepMounted
          open={Boolean(referringPhysicianAnchorEl)}
          onClose={handleReferringPhysicianClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          className={classes.navBarMenu}
        >
          <MenuItem className={classes.menuHeader}>
            Send to <br />
            {study.referring_physician}
          </MenuItem>
          <MenuItem className={classes.pageReferringPhysicianMenu}>
            <FormControl>
              <RadioGroup defaultValue="referring" name="radio-buttons-group">
                <FormControlLabel value="referring" control={<Radio />} label="+1 (123) 456-7890" />
                <FormControlLabel
                  value="other"
                  control={
                    <div>
                      <Radio />
                      <TextField size="small" label="Phone Number" variant="outlined" />
                    </div>
                  }
                  label=""
                />
                <br />
                <TextField size="large" label="Message" variant="filled" />
              </RadioGroup>
              <br />
              <ButtonGroup
                color="primary"
                variant="outlined"
                aria-label="outlined button group"
                className={classes.buttonGroup}
              >
                <Button
                  startIcon={<Textsms />}
                  variant="outlined"
                  className={classes.pageButton}
                  onClick={handleReferringPhysicianClose}
                >
                  Send Text
                </Button>
                <Button
                  startIcon={<Call />}
                  variant="outlined"
                  className={classes.pageButton}
                  onClick={handleReferringPhysicianClose}
                >
                  Send Page
                </Button>
              </ButtonGroup>
            </FormControl>
          </MenuItem>
        </Menu>
      </>
      <Tooltip title="Request Second Opinion is locked">
        <IconButton>
          <Send />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default StudyMenu;
