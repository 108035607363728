import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "regenerator-runtime/runtime";
import * as serviceWorker from "serviceWorker";

import { store } from "state/store";
import App from "App";
import config from "config";

Sentry.init({
  dsn: config.sentryDSN,
  environment: config.sentryEnv,
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
