import React, { useEffect, useState } from "react";
import "sweetalert2/src/sweetalert2.scss";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";
import config from "config";
import { theme } from "styles/theme";
import AppRoutes from "routes";
import AuthGuard from "components/Auth/AuthGuard";
import { logger } from "utils/logger";
import { useAuth } from "state/selector";
import { ChatProvider } from "hooks/useChat";
import { AppProvider } from "hooks/useApp";
import { BrowserRouter } from "react-router-dom";
import { Toaster, ToastBar, toast } from "react-hot-toast";

const App = () => {
  const { user } = useAuth();
  const [pubnubClient, setPubNubClient] = useState<PubNub | undefined>(undefined);

  useEffect(() => {
    if (user) {
      setPubNubClient(
        new PubNub({
          publishKey: config.pubnubPubKey,
          subscribeKey: config.pubnubSubKey,
          uuid: user.id,
          presenceTimeout: 60,
        })
      );
      logger.setUser(user);
    }
  }, [user, setPubNubClient]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthGuard>
        <BrowserRouter>
          {pubnubClient && (
            <PubNubProvider client={pubnubClient}>
              <ChatProvider>
                <AppProvider>
                  <AppRoutes />
                </AppProvider>
              </ChatProvider>
            </PubNubProvider>
          )}
          <Toaster
            position="bottom-right"
            toastOptions={{
              style: {
                background: "#0F172A",
                color: "white",
              },
              success: { duration: Infinity },
              error: { duration: Infinity },
            }}
          >
            {t => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <>
                    {icon}
                    {message}
                    {t.type !== "loading" && (
                      <div
                        style={{ width: "100%", height: "100%", position: "absolute", cursor: "pointer" }}
                        onClick={() => toast.dismiss(t.id)}
                      />
                    )}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster>
        </BrowserRouter>
      </AuthGuard>
    </ThemeProvider>
  );
};

export default App;
