import React, { useRef, useState } from "react";
import { TextField, Container, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Typography, Button } from "@ohif/ui";
import api from "api/api";

import useStyles from "styles/userProfileStyles";

const PasswordChangeContent = ({ handleClose }) => {
  const classes = useStyles();
  const confirmPasswordRef = useRef(null);
  const oldPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const [passwordError, setPasswordError] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const ChangePassword = async () => {
    const oldPassword = oldPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmNewPassword = confirmPasswordRef.current.value;

    const data = {
      old_password: oldPassword,
      new_password1: newPassword,
      new_password2: confirmNewPassword,
    };

    const payload = JSON.stringify(data);

    api
      .post(`/accounts/auth/password/change/`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => {
        setPasswordError("");
        alert("Password Change Successful");
        setIsValid(true);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 400) {
            if (validateNewPassword(newPassword)) {
              setPasswordError("Sorry, the old password you entered is incorrect. Please double-check and try again");
            } else {
              setPasswordError("Oops! The new password you entered is invalid. Please follow the rules below");
            }
          } else {
            console.error("An error occurred:", error.response.data);
          }
        } else {
          console.error("An error occurred:", error.message);
        }
      });
  };

  function validateNewPassword(password) {
    if (password.length < 8) {
      return false;
    }
    if (/^\d+$/.test(password)) {
      return false;
    }

    return true;
  }

  const onChangeData = async e => {
    if (isValid) {
      ChangePassword();
    } else {
      alert("New Passwords Are Not Matching");
    }
  };

  const validatePassword = async e => {
    const newPassword = newPasswordRef.current.value;
    const confirmNewPassword = confirmPasswordRef.current.value;
    if (newPassword === confirmNewPassword) {
      setPasswordError("");
      setIsValid(true);
    } else {
      setPasswordError("New passwords are not matching");
      setIsValid(false);
    }
  };

  const Section = ({ title, children }) => (
    <>
      <div className="border-b-2 border-black mb-2">
        <Typography variant="h5" className="flex grow text-primary-light font-light pb-2">
          {title}
        </Typography>
      </div>
      <div className="mt-4">{children}</div>
    </>
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <div className="p-2">
      <Container
        title="User Preferences"
        style={{ display: "flex", flexDirection: "column" }}
        className={classes.reportBtn}
      >
        <Section title="Change Password" />

        <div>
          <TextField
            name="Old Password"
            variant="outlined"
            inputRef={oldPasswordRef}
            label="Old Password"
            className={classes.textField}
            onKeyDown={onKeyDown}
            type={showOldPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowOldPassword}>
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div>
          <TextField
            name="New Password"
            variant="outlined"
            inputRef={newPasswordRef}
            label="New Password"
            className={classes.textField}
            onChange={e => validatePassword(e)}
            onKeyDown={onKeyDown}
            type={showNewPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowNewPassword}>
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={classes.textFieldContainer}>
          <TextField
            name="Confirm New Password"
            variant="outlined"
            label="Confirm New Password"
            inputRef={confirmPasswordRef}
            className={classes.secondaryTextField}
            onChange={e => validatePassword(e)}
            onKeyDown={onKeyDown}
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword}>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div style={{ color: "red", whiteSpace: "nowrap" }} className={classes.spanPasswordField}>
            {passwordError}
          </div>
        </div>
        <div>*Password must be at least 8 characters long, and cannot be entirely numeric</div>
        <div className={classes.bottomDiv}>
          *Password cannot be a commonly used password, nor be similar to personal info
        </div>

        <div className="flex flex-row justify-end">
          <Button variant="outlined" border="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="light" border="light" className="ml-2" onClick={onChangeData}>
            Save
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default PasswordChangeContent;
