import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getModelMetaData } from "api/metadata";
import { logger } from "utils/logger";
import { BaseState, BASE_INITIAL_STATE } from "state/reducers/base";

export type MetaDataState = BaseState & {
  priority: Array<Array<string>>;
  modalities: Array<Array<string>>;
  status: Array<Array<string>>;
  bodyPartExamined: Array<Array<string>>;
  imageOrientationPlane: Array<Array<string>>;
  viewportFlip: Array<Array<string>>;
};

export const INITIAL_STATE: MetaDataState = {
  ...BASE_INITIAL_STATE,
  priority: [],
  modalities: [],
  status: [],
  bodyPartExamined: [],
  imageOrientationPlane: [],
  viewportFlip: [],
};

export const getMetaData = createAsyncThunk("metaData/get", async () => getModelMetaData());

export const slice = createSlice({
  name: "metaData",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMetaData.fulfilled, (state, { payload }) => {
      const {
        PriorityEnum,
        StatusEnum,
        ModalityEnum,
        BodyPartExaminedEnum,
        ImageOrientationPlaneEnum,
        ViewportFlipEnum,
      } = payload.components.schemas;
      state.priority = PriorityEnum.choices;
      state.status = StatusEnum.choices;
      state.modalities = ModalityEnum.choices;
      state.bodyPartExamined = BodyPartExaminedEnum.enum;
      state.imageOrientationPlane = ImageOrientationPlaneEnum.enum;
      state.viewportFlip = ViewportFlipEnum.enum;
      state.isLoading = false;
    });

    builder.addCase(getMetaData.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getMetaData.rejected, state => {
      state.isLoading = false;
      logger.error("Failed to get metadata");
    });
  },
});

export default slice.reducer;
