import React from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { AppBar, Box, IconButton, Tooltip, Toolbar } from "@material-ui/core";
import { getNameInitials, getPredefinedColor } from "@pubnub/react-chat-components";
import RecordingListDialog from "components/RecordingDialog/RecordingListDialog";

import useStyles from "styles/appStyles";
import { theme } from "styles/theme";
import { useChat } from "hooks/useChat";
import { useApp } from "hooks/useApp";
import UserProfileMenu from "components/NavBar/UserProfileMenu";
import LeftNavBarLinks from "components/NavBar/LeftNavBarLinks";
import StudyMenu from "components/NavBar/StudyMenu";

const ONLINE_STUDY_VIEWER_LIMIT = 4;

const NavBar = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { unreadMessageCounts, studyUUIDs, userList } = useChat();
  const { patient, study } = useApp();
  const [recordingDialogOpen, setRecordingDialogOpen] = React.useState(false);

  const totalUnreadMessageCounts = Object.values(unreadMessageCounts).reduce(
    (prevVal, currentVal) => prevVal + currentVal,
    0
  );

  return (
    <AppBar position="static" style={{ background: "linear-gradient(to right, #6b21a8, #a855f7)" }}>
      <Box>
        <Toolbar className={classes.customizeToolbar}>
          <LeftNavBarLinks totalUnreadMessageCounts={totalUnreadMessageCounts} />
          {pathname.startsWith("/study/") && patient && (
            <Box style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
              {patient.name}&nbsp;&nbsp;|&nbsp;&nbsp;{patient.sex}&nbsp;&nbsp;|&nbsp;&nbsp;
              {patient.age}Y&nbsp;&nbsp;|&nbsp;&nbsp;{patient.mrn}
            </Box>
          )}
          <div className={classes.toolbarButtons}>
            {studyUUIDs.slice(0, ONLINE_STUDY_VIEWER_LIMIT).map(uuid => {
              const studyUser = userList.find(u => u.id === uuid);
              const studyUserProfilePicture = studyUser.profile_picture;

              return studyUser ? (
                <Tooltip title={studyUser.name} key={studyUser.id}>
                  <IconButton className={classes.studyOnlineUser} key={uuid}>
                    <div
                      className={cn(classes.profilePhoto, classes.studyOnlineUserPhoto)}
                      style={{
                        background: `${
                          studyUserProfilePicture ? `url(${studyUserProfilePicture})` : getPredefinedColor(studyUser.id)
                        }`,
                      }}
                    >
                      {!studyUserProfilePicture && getNameInitials(studyUser.name)}
                    </div>
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              );
            })}

            {studyUUIDs.length > ONLINE_STUDY_VIEWER_LIMIT && (
              <Tooltip
                title={
                  <span
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{
                      __html: studyUUIDs
                        .map(uuid => {
                          const studyUser = userList.find(u => u.id === uuid);

                          return studyUser?.name || "";
                        })
                        .join("<br/>"),
                    }}
                  />
                }
              >
                <IconButton className={classes.studyOnlineUser}>
                  <div
                    className={cn(classes.profilePhoto, classes.studyOnlineUserPhoto)}
                    style={{ backgroundColor: `${theme.palette.other.light}` }}
                  >
                    {studyUUIDs.length - ONLINE_STUDY_VIEWER_LIMIT}+
                  </div>
                </IconButton>
              </Tooltip>
            )}

            {pathname.startsWith("/study/") && study && (
              <StudyMenu study={study} setRecordingDialogOpen={setRecordingDialogOpen} />
            )}
          </div>
          <UserProfileMenu />
        </Toolbar>
      </Box>
      {study && <RecordingListDialog study={study} open={recordingDialogOpen} onClose={setRecordingDialogOpen} />}
    </AppBar>
  );
};

export default NavBar;
